import axios, { AxiosResponse } from "axios";

import config from "../config.json";

let configRoutes: API.ConfigRoutes = {
    main: {
        v1: {
            tokens: "",
            devices: "",
            images: "",
        },
        v2: {
            devices: "",
        },
    },
    product: {
        v1: "",
    },
    storage: {
        v1: "",
        v2: "",
    },
};

const { env, apiKeysVersion, reduxVersion } = config;

type EnvConfig = {
    apiLegacyUrl: string;
    homepageUrl: string;
    apiGatewayUrl: string;
    paypalClientId: string;
    authClientId: string;
    authClientSecret: string;
};

type EnvConfigs = {
    Development: EnvConfig;
    Staging: EnvConfig;
    Production: EnvConfig;
    Test: EnvConfig;
};

export function getEnv(): keyof EnvConfigs {
    const envName = process.env.REACT_APP_ENVIRONMENT;
    if (envName !== "Development" && envName !== "Staging" && envName !== "Production" && envName !== "Test") {
        throw new Error("Environment name not found");
    }

    return envName;
}

export function getApiGatewayUrl(): string {
    const name = getEnv();
    const _env = env[name];
    return `${_env.apiGatewayUrl}`;
}

export function getHomepageUrl(): string {
    const name = getEnv();
    const _env = env[name];
    return _env.homepageUrl;
}

export function getApiUrl(): string {
    const name = getEnv();
    const _env = env[name];
    return `${_env.apiLegacyUrl}/api`;
}

export function getPayPalClientId(): string {
    const name = getEnv();
    const _env = env[name];
    return _env.paypalClientId;
}

export function getProductManagerUrl(): string {
    const version = "v1";
    return configRoutes.product[version].slice(0, -1);
}

export function getStorageManagerUrl(): string {
    const version = "v1";
    return configRoutes.storage[version].slice(0, -1);
}

type AuthClient = {
    clientId: string;
    clientSecret: string;
};

export function getAuthClient(): AuthClient {
    const name = getEnv();
    const _env = env[name];
    return {
        clientId: _env.authClientId,
        clientSecret: _env.authClientSecret,
    };
}

export function getApiKeysVersion(): string {
    if (typeof apiKeysVersion !== "string") {
        return "";
    }

    return apiKeysVersion;
}

export function getReduxVersion(): string {
    if (typeof reduxVersion !== "string") {
        return "";
    }

    return reduxVersion;
}

export function getManagementUrl(): string {
    const envName = getEnv();
    const envConfig = env[envName];

    return `${envConfig.deviceManagement}/Home/ListDevices`;
}

export const fetchConfigRoutes = async () => {
    try {
        const response = await axios.get<object, AxiosResponse<API.ConfigRoutes>>(
            getApiGatewayUrl() + "/v1/configs/routes",
        );
        configRoutes = response.data;
        return response.data;
    } catch (err) {
        console.error(err);
    }
};
