import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { unregister } from "./registerServiceWorker";
import { fetchConfigRoutes } from "src/services/configService";
import { LoadingBox } from "src/ui/shared/Loading";

import "@fortawesome/fontawesome-free/css/all.css";
import "flag-icon-css/css/flag-icon.css";
import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";

const App = React.lazy(() => import("./App"));

const initialize = async () => {
    const configRoutes = await fetchConfigRoutes();

    createRoot(document.getElementById("root")).render(<Suspense>{configRoutes ? <App /> : <LoadingBox />}</Suspense>);

    unregister();
};

initialize();
