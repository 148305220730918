import { Icon, IconSize } from "@dashboard/devices/components/shared/Icon";
import { makeStyles } from "@material-ui/core";

export function LoadingIcon({ size, className }: { size?: IconSize; className?: string }): JSX.Element {
    return <Icon name="fas fa-spinner fa-spin" className={className} size={size} />;
}

export const LoadingBox = () => {
    const classes = makeStyles(() => ({
        loadingBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexGrow: 1,
        },
    }))();

    return (
        <div className={classes.loadingBox}>
            <LoadingIcon size={IconSize.large} />
        </div>
    );
};
